.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.textImage {
  margin-top: 32px;
  margin-bottom: 16px;
}

.description {
  margin-top: 4px;
  margin-bottom: 24px;
  text-align: center;
}
